.header {
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
  max-width: 1180px;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;
}

.navigationBlock {
  display: flex;
  order: 1;
}

.listItems {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-template-rows: auto;
  grid-auto-flow: row;
  align-items: center;
  gap: 50px;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.listItem {
  display: block;
  padding: 0;
  margin: 0;
}

.listItemLink {
  padding: 0;
  margin: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  color: black;
  outline: none;
  text-rendering: optimizeLegibility;
  transition: color 0.5s ease-in-out;
}

.lngChanger {
  display: flex;
  order: 2;
  align-self: center;
  align-content: center;
  gap: 5px;
  padding: 0;
  margin: 0;
  max-height: 31px;
  border: none;
  background-color: transparent;
}

.lngChangerText {
  margin: 0;
  padding: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
  color: black;
  transition: color 0.5s ease-in-out;
  text-align: center;
}

.lngSwitcher {
  max-width: 30px;
  max-height: 20px;
  align-self: center;
  transition: fill 0.5s ease-in-out;
}

@media (min-width: 639px) {
  .listItemLink:hover {
    cursor: pointer;
    color: #F74843;
  }

  .lngChanger:hover {
    cursor: pointer;
    color: #F74843;
    fill: #F74843;
  }

  .lngChanger:hover .lngChangerText {
    color: #F74843;
  }
}

@media (max-width: 1000px) {
  .listItems {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
    gap: 20px;
  }

  .lngChanger {
    align-self: flex-start;
  }
}

@media (max-width: 769px) {
  .container {
    padding: 30px;
    gap: 40px;
  }
}

@media (max-width: 610px) {
  .container {
    flex-direction: column;
  }

  .lngChanger {
    order: 1;
    align-self: flex-end;
  }

  .navigationBlock {
    order: 2;
  }
}

@media (max-width: 501px) {
  .container {
    padding: 20px;
  }
}

@media (max-width: 428px) {
  .listItemLink {
    font-size: 18px;
  }

  .navigationBlock {
    justify-content: center;
  }

  .listItems {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    justify-items: center;
  }

  .listItemFooter {
    grid-column: span 2;
  }
}

@media (max-width: 325px) {
  .listItems {
    gap: 5px;
  }
}
