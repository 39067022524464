.card {
  display: grid;
  grid-template-columns: 70% 30%;
  padding: 20px;
  width: 580px;
  height: 280px;
  border: 3px solid black;
  border-radius: 20px;
  border-color: black;
  box-sizing: border-box;
}

.cardText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 20px;
}

.cardYear {
  margin: 0;
  padding: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
}

.cardCompanyName {
  margin: 0;
  padding: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
  transition: color 0.5s ease-in-out;
}

.cardTitle {
  margin: 0;
  padding: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
}

.cardButton {
  background-color: #F74843;
  color: white;
  border: none;
  border-radius: 20px;
  box-sizing: border-box;
  width: 50%;
  align-self: center;
  opacity: 0;
  height: 0;
  text-rendering: optimizeLegibility;
  transition:
    opacity 0.5s ease-in-out,
    height 0.5s ease-in-out;
}

.cardButtonText {
  margin: 0;
  padding: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.logoPic {
  width: 100%;
  align-self: center;
  justify-self: end;
  overflow: visible;
  transition:
    width 0.5s ease-in-out,
    height 0.5s ease-in-out;
}



@media (min-width: 639px) {
  .card:hover {
    cursor: pointer;
  }

  .card:hover .cardText {
    justify-content: space-between;
  }

  .card:hover .cardCompanyName {
    color: #F74843;
  }

  .card:hover .cardButton {
    opacity: 1;
    height: 30px;
    cursor: pointer;
  }

  .card:hover .logoPic {
    width: 105%;
  }
}

@media (max-width: 639px) {
  .card {
    height: 250px;
    width: 100%;
  }

  .cardText {
    padding-right: 10px;
  }

  .cardYear {
    font-size: 14px;
  }

  .cardCompanyName {
    font-size: 24px;
  }

  .cardTitle {
    font-size: 18px;
  }

  .cardButton {
    opacity: 1;
    height: 20px;
    width: 100px;
  }

  .cardButtonText {
    font-size: 12px;
  }
}

@media (max-width: 440px) {
  .card {
    padding: 10px;
    grid-template-columns: 75% 25%;
  }

  .cardIncreasedLogo {
    grid-template-columns: 70% 30%;
  }

  .cardCompanyName {
    font-size: 18px;
  }

  .cardTitle {
    font-size: 16px;
  }
}
