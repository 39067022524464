html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'VremenaGrotesk';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/vremenagrotesk.woff2') format('woff2'), url('fonts/vremenagrotesk.woff') format('woff');
}

@font-face {
  font-family: 'VremenaGrotesk';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/vremenagroteskbold.woff2') format('woff2'), url('fonts/vremenagroteskbold.woff') format('woff');
}

@font-face {
  font-family: 'VremenaGrotesk';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/vremenagroteskbook.woff2') format('woff2'), url('fonts/vremenagroteskbook.woff') format('woff');
}
