.modalWindow {
  width: 60vw;
  max-width: 700px;
  max-height: fit-content;
  box-sizing: border-box;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
  background-color: white;
  border-radius: 12px;
  padding: 30px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.showModalWindow {
  opacity: 1;
}

.closeIcon {
  background-image: url('../../images/close_button.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  border: 0;
  display: block;
  max-height: 32px;
  max-width: 32px;
  height: 100%;
  width: 100%;
  position: absolute;
  right: -40px;
  top: -40px;
}

.closeIcon:hover {
  cursor: pointer;
}

@media (max-width: 1166px) {
  .modalWindow {
    width: 80vw;
  }
}

@media (max-width: 580px) {
  .modalWindow {
    max-height: 70vh;
  }

  .closeIcon {
    right: -20px;
    top: -20px;
    max-height: 16px;
    max-width: 16px;
  }
}

@media (max-height: 670px) {
  .modalWindow {
    max-height: 70vh;
  }
}
