.modalContentRenderGeneralWindow {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.modalContentRenderGeneralWindow::-webkit-scrollbar  {
  width: 0;
  height: 0;
}

.modalContentRenderTextLogoWindow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalContentRenderCompanyTextBox {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  gap: 10px;
}

.modalContentRendercardYear {
  padding: 0;
  margin: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
}

.modalContentRenderCompanyLinkBox {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modalContentRendercardCompanyName {
  padding: 0;
  margin: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
}

.modalContentRenderlink {
  padding: 0;
  margin: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  outline: none;
  color: black;
  transition: color 0.5s ease-in-out;
}

.modalContentRendercardTitle {
  padding: 0;
  margin: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
}

.modalContentRenderlogoPic {
  align-self: center;
  width: 25%;
}

.modalContentRenderParagraphBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modalContentRenderParagraph {
  padding: 0;
  margin: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
  /* text-align: justify */
}

.modalContentRenderlink:hover {
  color: #F74843;
  cursor: pointer;
}

@media (max-width: 867px) {
  .modalContentRenderCompanyTextBox {
    gap: 20px;
  }
}

@media (max-width: 650px) {
  .modalContentRendercardYear {
    font-size: 16px;
  }
  .modalContentRendercardCompanyName {
    font-size: 30px;
  }
  .modalContentRenderlink {
    font-size: 14px;
  }
  .modalContentRendercardTitle {
    font-size: 20px;
  }
  .modalContentRenderParagraph {
    font-size: 16px;
  }
}

@media (max-width: 580px) {
  .modalContentRenderTextLogoWindow {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .modalContentRenderlogoPic {
    width: 50%;
  }
  .modalContentRenderCompanyTextBox {
    padding-right: 0px;
    gap: 20px;
  }
}

@media (max-width: 501px) {
  .modalContentRendercardYear {
    font-size: 16px;
  }
  .modalContentRendercardCompanyName {
    font-size: 26px;
  }
  .modalContentRenderlink {
    font-size: 14px;
  }
  .modalContentRendercardTitle {
    font-size: 18px;
  }
  .modalContentRenderParagraph {
    font-size: 16px;
  }
}
