.modalOverlay {
  display: flex;
  background-color: #000000;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: opacity 0.5s ease-in-out;
}

.showModalOverlay {
  opacity: 0.6;
}
