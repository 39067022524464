.about {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1180px;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;
}

.header {
  padding: 0;
  margin: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
  text-align: center;
}

.paragraph {
  padding: 0;
  margin: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
  /* text-align: justify; */
}

@media (max-width: 1000px) {
  .header {
    font-size: 32px;
  }

  .paragraph {
    font-size: 22px;
  }
}

@media (max-width: 769px) {
  .container {
    padding: 30px;
  }
}

@media (max-width: 501px) {
  .container {
    padding: 20px;
    gap: 10px;
  }

  .header {
    font-size: 28px;
  }
}

@media (max-width: 372px) {
  .header {
    font-size: 24px;
  }

  .paragraph {
    font-size: 18px;
  }
}
