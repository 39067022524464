.title {
  width: 100%;
}

.container {
  display: flex;
  align-items: center;
  gap: 50px;
  max-width: 1180px;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;
}

.photo {
  background-image: url('../../images/website_photo.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  width: 100vw;
  height: 100vh;
  max-height: 250px;
  max-width: 250px;
}

.textBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header {
  padding: 0;
  margin: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
}

.paragraph {
  padding: 0;
  margin: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
}

@media (max-width: 1085px) {
  .container {
    gap: 20px;
  }
}

@media (max-width: 1000px) {
  .photo {
    max-height: 200px;
    max-width: 200px;
  }

  .header {
    font-size: 48px;
  }

  .paragraph {
    font-size: 28px;
  }
}

@media (max-width: 847px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .photo {
    max-height: 250px;
    max-width: 250px;
  }

  .header {
    text-align: center;
  }

  .paragraph {
    text-align: center;
  }
}

@media (max-width: 769px) {
  .container {
    padding: 30px;
  }
}

@media (max-width: 501px) {
  .container {
    padding: 20px;
    gap: 10px;
  }

  .textBlock {
    gap: 10px;
  }

  .header {
    font-size: 36px;
  }

  .paragraph {
    font-size: 24px;
  }
}

@media (max-width: 372px) {
  .header {
    font-size: 36px;
  }

  .paragraph {
    font-size: 24px;
  }
}
