.footer {
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
  max-width: 1180px;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;

}

.contactsBlock {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.text {
  margin: 0;
  padding: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
}

.navigationList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.navigationListItem {
  margin: 0;
  padding: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizeLegibility;
}

.link {
  display: flex;
  gap: 3px;
  align-items: center;
  text-decoration: none;
  outline: none;
  color: black;
  transition: color 0.5s ease-in-out;
}

.linkPic {
  margin: 0;
  padding: 0;
  max-height: 30px;
  max-width: 30px;
}

.year {
  margin: 0;
  padding: 0;
  font-family: 'VremenaGrotesk', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: normal;
  transition: all 0.5s linear;
  text-rendering: optimizeLegibility;
  align-self: center;
}

@media (min-width: 639px) {
  .link:hover {
    cursor: pointer;
    color: #F74843;
  }

}

@media (max-width: 769px) {
  .container {
    padding: 30px;
    flex-direction: column;
    gap: 40px;
  }
}

@media (max-width: 640px) {
  .contactsBlock {
    flex-direction: column;
    gap: 10px;
  }

  .navigationList {
    justify-content: center;
  }
}

@media (max-width: 501px) {
  .container {
    padding: 20px;
  }

  .navigationList {
    gap: 10px;
    flex-direction: column;
  }

  .contactsBlock {
    gap: 10px;
  }
}
